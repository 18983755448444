import React from "react";
import SignInControl from "./signin-control/signInControl.js";
import Header from "./header/Header.js";
import PropTypes from "prop-types";
import { getI18n, Trans } from "react-i18next";
import Alert from "@cx/ui/Alert";
import "./app.scss";
import {
  endSession,
  identifyUser,
  initializeDataLayer,
  linkClicked,
  productEvent,
  report
} from "../api/analytics";
import { withRouter } from "react-router-dom";
import Footer from "./footer/Footer";
import BroadcastMessage from "./broadcast-message/BroadcastMessage";
import { mapToTranslatedMessages } from "./password-validation/translationMap";

const timeoutThresholds = {
  initialTimeout: 600, // How long before we go to the expired screen
  info: 60, // When to show it as in Info box
  warningTime: 30 // when to show it as a Warning box
};
let intervalHolder;

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: this.props.username || "",
      password: "",
      errorMsg: undefined,
      errorAttrs: undefined,
      timeoutTimestamp: Date.now() + timeoutThresholds.initialTimeout * 1000,
      timeRemaining: timeoutThresholds.initialTimeout,
      solution:
        this.props.solution !== undefined
          ? JSON.parse(this.props.solution)
          : undefined,
      solutionList: [],
      solutionInfo: undefined,
      availableSolutions: [],
      apiBaseUrl: this.props.apiBaseUrl || "http://localhost:3001",
      transactionId: this.props.transactionId,
      authenticationProvider: undefined,
      authenticationProviderPasswordRecoveryUrl: undefined,
      usernameRecoveryEmail: undefined,
      accountRecoveryWorkflowUrl: undefined,
      verificationFactors: [],
      enrollFactor: null,
      unblock: null,
      thirdPartyCookiesAllowed: undefined,
      passwordChangeBackendErrors: []
    };

    this.solutionListUpdateCallback = this.solutionListUpdateCallback.bind(
      this
    );
    this.submitUsername = this.submitUsername.bind(this);
    this.forgotUsername = this.forgotUsername.bind(this);
    this.submitPassword = this.submitPassword.bind(this);
    this.submitSolutionName = this.submitSolutionName.bind(this);
    this.changePassword = this.changePassword.bind(this);
    this.navigateBackAPage = this.navigateBackAPage.bind(this);
    this.clearErrors = this.clearErrors.bind(this);
    this.handleSignin = this.handleSignin.bind(this);
    this.federate = this.federate.bind(this);
    this.handleErrorState = this.handleErrorState.bind(this);
    this.onAccountRecoveryPasswordChange = this.onAccountRecoveryPasswordChange.bind(
      this
    );
    this.onAcceptEula = this.onAcceptEula.bind(this);
    this.fetchIdentityProviders = this.fetchIdentityProviders.bind(this);
    this.getDTBackdoorLink = this.getDTBackdoorLink.bind(this);
    this.submitFactorEnrollmentOption = this.submitFactorEnrollmentOption.bind(
      this
    );
    this.onEnrollFactor = this.onEnrollFactor.bind(this);
    this.onVerifyEnrollFactor = this.onVerifyEnrollFactor.bind(this);
    this.onEnrollSuccess = this.onEnrollSuccess.bind(this);
    this.continueSignin = this.continueSignin.bind(this);
  }

  componentDidMount() {
    if (this.props.currentFlow !== "solutionIdentity") {
      intervalHolder = setInterval(() => {
        if (this.state.timeRemaining <= 1) {
          clearInterval(intervalHolder);
          report(
            "Page",
            "System Timed Out",
            "Shown Timeout Screen",
            this.props.location.state
          );

          this.props.history.replace(
            this.props.bookMarkSolution,
            "timeoutErrorPage"
          );
          this.props.history.block(); // returns a function that can unblock, can take in function to provide prompt
          this.setState({ timeRemaining: 0 }); // Hides the alert box
        } else {
          this.setState({
            timeRemaining: Math.ceil(
              (this.state.timeoutTimestamp - Date.now()) / 1000
            )
          });
        }
      }, 1000);
    }
    // initialize gtm data layer
    initializeDataLayer(this.props.solutionId);

    // Force the error (added for callback endpoint)
    if (this.props.forceErrorState) {
      this.handleErrorState(this.props.forceErrorState, null);
    }
  }

  solutionListUpdateCallback() {
    if (
      this.state.solutionList === undefined ||
      this.state.solutionList.length < 1
    ) {
      this.setState({
        errorMsg: "error.generic"
      });
      if (window.NREUM)
        window.NREUM.noticeError(
          new Error("SolutionList was undefined or length of 0"),
          {
            transactionId: this.state.transactionId
          }
        );
      return; // Return so we don't show white page due to undefined error.
    }

    if (this.state.solutionList.length === 1) {
      this.submitSolutionName(this.state.solutionList[0]);
    } else if (this.state.solutionList.length > 1) {
      this.props.history.push(
        this.props.bookMarkSolution,
        "disambiguationPage"
      );
      this.setState({
        errorMsg: ""
      });
    }
  }

  submitSolutionName(solutionInfo) {
    if (solutionInfo.passwordRequired) {
      this.setState({
        authenticationProvider: solutionInfo.solutionId,
        authenticationProviderPasswordRecoveryUrl:
          solutionInfo.passwordRecoveryUrl,
        solutionInfo,
        errorMsg: ""
      });
      this.props.history.push(this.props.bookMarkSolution, "passwordPage");
    } else {
      // if passwordRequired === false, start federation
      if (solutionInfo.federationProvider !== undefined) {
        this.federate(solutionInfo.federationProvider);
      } else {
        // TODO: handle error
      }
    }
  }

  submitUsername(newUsername) {
    const trimmedUsername = newUsername.trim();
    const identityParams = {
      username: trimmedUsername,
      solutionId: this.props.solutionId,
      /* eslint-disable-next-line camelcase */
      client_id: this.props.params.client_id
    };

    this.fetchIdentityProviders(identityParams)
      .then(result => {
        this.setState(
          {
            solutionList: result.items,
            username: trimmedUsername
          },
          () => {
            this.solutionListUpdateCallback();
          }
        );
      })
      .catch(err => {
        this.setState({
          errorMsg: "error.generic",
          errorAttrs: {
            ...this.state.errorAttrs,
            canShowDtLegacyLink: true
          }
        });
        if (window.NREUM)
          window.NREUM.noticeError(err, {
            transactionId: this.state.transactionId
          });
      });

    this.reportThirdPartyCookieStatus(identityParams);
  }

  submitFactorEnrollmentOption(factor) {
    this.setState({ enrollFactor: { factorType: factor } });
    this.props.history.push(this.props.bookMarkSolution, "enrollFactorPage");
  }

  fetchIdentityProviders(params) {
    if (this.props.idp) {
      return Promise.resolve({ items: [this.props.idp] });
    } else {
      const url = new URL(this.state.apiBaseUrl + "/identity-providers");
      Object.keys(params).forEach(key =>
        url.searchParams.append(key, params[key])
      );

      return fetch(url, {
        headers: {
          Accept: "application/vnd.coxauto.v1+json",
          "X-CoxAuto-Correlation-Id": this.state.transactionId
        },
        mode: "cors"
      }).then(response => {
        if (!response.ok) {
          throw response;
        }
        return response.json(); // we only get here if there is no error
      });
    }
  }

  forgotUsername() {
    this.props.history.push(
      this.props.bookMarkSolution,
      "usernameRecoveryPage"
    );

    this.setState({
      errorMsg: ""
    });
  }

  forgotPassword = () => {
    // this.state.authenticationProvider:
    // = BRI ~ Bridge 2 user
    // = any other value ~ Solution user
    // test comment
    if (this.state.authenticationProvider === "BRI") {
      // Switch to forgot password recovery options page for Bridge 2 users.
      this.props.history.push(
        this.props.bookMarkSolution,
        "passwordRecoveryOptionsForm"
      );
    } else {
      // Follow Solution user password reset flow.
      window.location.assign(this.state.solutionInfo.passwordResetUrl);
    }
  };

  // Handle recovering bridge username.
  recoverBridgeUsername = shortCircuit => {
    if (shortCircuit) {
      this.props.history.replace(
        this.props.bookMarkSolution,
        "usernameRecoveryEmailCaptureForm"
      );
    } else {
      this.props.history.push(
        this.props.bookMarkSolution,
        "usernameRecoveryEmailCaptureForm"
      );
    }
  };

  // Handle next page from username recovery.
  onClickRecoveryEmailNextButton = recoveryEmail => {
    this.setState({ usernameRecoveryEmail: recoveryEmail });
    const SendUsernameRequest = {
      clientId: this.props.params.client_id,
      emailAddress: recoveryEmail
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/vnd.coxauto.v1+json",
        Accept: "application/vnd.coxauto.v1+json",
        "Access-Control-Allow-Credentials": "true",
        "X-CoxAuto-Correlation-Id": this.state.transactionId
      },
      body: JSON.stringify(SendUsernameRequest)
    };
    fetch(
      new URL(this.state.apiBaseUrl + "/account/recovery/username").href,
      requestOptions
    )
      .then(response => response.json())
      .then(sendUsernameResponse => {
        this.props.history.push(
          this.props.bookMarkSolution,
          "usernameRecoveryStatusForm"
        );
        this.clearErrors();
      })
      .catch(err => {
        this.setState({
          errorMsg: "error.generic"
        });
      });
  };

  // Handle moving back to recovery optins page from passcode page
  showRecoveryOptions = () => {
    this.props.history.push(
      this.props.bookMarkSolution,
      "passwordRecoveryOptionsForm"
    );
  };

  // Handle moving back from username recovery status.
  onClickRecoverUsernameStatusBack = () => {
    this.props.history.push(this.props.bookMarkSolution, "usernamePage");
  };

  initiatePasswordRecovery = () =>
    fetch(new URL("/account/recovery/email", this.state.apiBaseUrl).href, {
      method: "POST",
      headers: {
        "Content-Type": "application/vnd.coxauto.v1+json",
        Accept: "application/vnd.coxauto.v1+json",
        "X-CoxAuto-Correlation-Id": this.state.transactionId
      },
      body: JSON.stringify({
        method: "EMAIL",
        userName: this.state.username,
        clientId: this.props.params.client_id
      })
    })
      .then(response => {
        if (!response.ok) {
          throw response;
        }
        this.clearErrors();
      })
      .catch(err => {
        this.setState({
          errorMsg: "error.generic"
        });
        throw err;
      });

  retrieveRecoveryWorkflow = ({ method, path }) =>
    fetch(new URL(this.state.apiBaseUrl + path).href, {
      method: "POST",
      headers: {
        "Content-Type": "application/vnd.coxauto.v1+json",
        Accept: "application/vnd.coxauto.v1+json",
        "X-CoxAuto-Correlation-Id": this.state.transactionId
      },
      body: JSON.stringify({
        method,
        userName: this.state.username,
        clientId: this.props.params.client_id
      })
    })
      .then(response => {
        if (!response.ok) {
          throw response;
        }
        return response.json();
      })
      .then(passwordWorkflowResponse => {
        this.clearErrors();
        return passwordWorkflowResponse;
      });

  onClickPasswordRecoveryByEmailFromOptionsForm = () =>
    this.initiatePasswordRecovery()
      .then(() =>
        this.props.history.push(
          this.props.bookMarkSolution,
          "accountRecoveryEmailSentForm"
        )
      )
      .catch(() => {});

  onClickPasswordRecoveryBySmsFromOptionsForm = () =>
    this.retrieveRecoveryWorkflow({
      method: "SMS",
      path: "/account/recovery/sms"
    })
      .then(passwordWorkflowResponse =>
        this.setState({
          accountRecoveryWorkflowUrl: passwordWorkflowResponse.workflowUrl,
          passwordPolicy: passwordWorkflowResponse.passwordPolicy
        })
      )
      .then(() =>
        this.props.history.push(
          this.props.bookMarkSolution,
          "otpValidationForm"
        )
      )
      .catch(() => {
        this.setState({
          errorMsg: "error.generic"
        });
      });

  closePasswordRecovery = e => {
    this.props.history.push(this.props.bookMarkSolution, "passwordPage");
  };

  // Handle Passcode verfication for reset password by Phone.
  verifyPasscode = passcode => {
    // Make signin-api AJAX REST call to password reset
    const SMSRecoveryRequest = {
      clientId: this.props.params.client_id,
      passcode,
      workflowUrl: this.state.accountRecoveryWorkflowUrl
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/vnd.coxauto.v1+json",
        Accept: "application/vnd.coxauto.v1+json",
        "Access-Control-Allow-Credentials": "true",
        "X-CoxAuto-Correlation-Id": this.state.transactionId
      },
      body: JSON.stringify(SMSRecoveryRequest)
    };
    return fetch(
      new URL(this.state.apiBaseUrl + "/account/recovery/sms/verify").href,
      requestOptions
    )
      .then(response => {
        if (!response.ok) {
          throw new Error("error.generic");
        }
        return response.json();
      })
      .then(response => {
        if (!response.workflowUrl) {
          throw new Error("error.invalidOtpCode");
        }
        return response;
      })
      .then(passcodeVerifyResponse => {
        this.clearErrors();

        this.setState({
          accountRecoveryWorkflowUrl: passcodeVerifyResponse.workflowUrl
        });
        this.props.history.push(
          this.props.bookMarkSolution,
          "accountRecoveryPasswordResetForm"
        );
        return true;
      })
      .catch(({ message }) => {
        this.setState({
          errorMsg: message
        });
        return false;
      });
  };

  // Handle Passcode verfication for reset password by Phone.
  resendPasscode = () => {
    // Make signin-api AJAX REST call to password reset
    const SMSResendRequest = {
      clientId: this.props.params.client_id,
      workflowUrl: this.state.accountRecoveryWorkflowUrl
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/vnd.coxauto.v1+json",
        Accept: "application/vnd.coxauto.v1+json",
        "Access-Control-Allow-Credentials": "true",
        "X-CoxAuto-Correlation-Id": this.state.transactionId
      },
      body: JSON.stringify(SMSResendRequest)
    };
    fetch(
      new URL(this.state.apiBaseUrl + "/account/recovery/sms/resend").href,
      requestOptions
    )
      .then(response => {
        if (!response.ok) {
          throw response;
        }
        return response.json();
      })
      .then(passcodeResendResponse => {
        this.clearErrors();
        this.setState({
          accountRecoveryWorkflowUrl:
            passcodeResendResponse.workflowUrl ||
            this.state.accountRecoveryWorkflowUrl
        });
      })
      .catch(err => {
        this.setState({
          errorMsg: "error.generic"
        });
      });
  };

  // TODO: Review security -- what prevents MITM attacks?  What ensures requests originate from the UI?
  // TODO: Refactor / de-dupe HTTP boilerplate around AccountRecovery
  onAccountRecoveryPasswordChange(newPassword) {
    // DISALLOWED_STRINGS_FOR_PASSWORDS_FF is set, use the newer code.
    return fetch(
      new URL(
        "/account/recovery/reset-password",
        this.state.apiBaseUrl
      ).toString(),
      {
        method: "POST",
        headers: {
          "Content-Type": "application/vnd.coxauto.v1+json",
          Accept: "application/vnd.coxauto.v1+json",
          "X-CoxAuto-Correlation-Id": this.state.transactionId
        },
        body: JSON.stringify({
          workflowUrl: this.state.accountRecoveryWorkflowUrl,
          password: newPassword,
          clientId: this.props.params.client_id
        })
      }
    )
      .then(apiResponse =>
        apiResponse.json().then(json => {
          json.apiResponseOk = apiResponse.ok;
          json.apiResponseStatus = apiResponse.status;
          return json;
        })
      )
      .then(json => {
        if (json.apiResponseOk) {
          // happy path
          this.clearErrors();
          this.setState({
            accountRecoveryWorkflowUrl: json.workflowUrl
          });
          return json;
        } else {
          // failure path
          throw json;
        }
      })
      .catch(failedJson => {
        if (
          failedJson.apiResponseStatus &&
          (failedJson.apiResponseStatus === 400 ||
            failedJson.apiResponseStatus === 422)
        ) {
          // Display the password error messages.
          this.setState({
            passwordChangeBackendErrors: mapToTranslatedMessages(
              failedJson.passwordErrorCodes
            )
          });
        } else {
          this.setState({ errorMsg: `error.generic` });
        }
        return false;
      });
  }

  federate(federationProvider) {
    const submitParams = JSON.parse(JSON.stringify(this.props.params));
    submitParams.federationProvider = federationProvider;
    this.handleSignin(submitParams);
  }

  submitPassword(newPassword) {
    const submitParams = JSON.parse(JSON.stringify(this.props.params));
    submitParams.password = newPassword;
    this.setState({
      password: newPassword
    });
    submitParams.solutionid = this.props.solutionId;
    submitParams.authenticationProvider = this.state.authenticationProvider;
    return this.handleSignin(submitParams);
  }

  changePassword(newPassword) {
    const url = new URL(this.state.apiBaseUrl + "/change-password"),
      body = {
        clientId: this.props.params.client_id,
        token: this.state.passwordResetState,
        oldPassword: this.state.password,
        newPassword
      };
    return fetch(url.href, {
      headers: {
        Accept: "application/vnd.coxauto.v1+json",
        "Content-Type": "application/vnd.coxauto.tokenchangepassword.v1+json",
        "X-CoxAuto-Correlation-Id": this.state.transactionId
      },
      method: "POST",
      body: JSON.stringify(body),
      mode: "cors"
    })
      .then(response => response.json())
      .then(response => {
        if (Object.entries(response).length === 0) {
          this.clearErrors();
          return true;
        } else {
          this.handleErrorState(
            response.errorCode,
            response.errorAttributes,
            response.passwordErrorCodes
          );
          return false;
        }
      })
      .catch(err => {
        this.setState({
          errorMsg: "error.generic"
        });
        if (window.NREUM)
          window.NREUM.noticeError(err, {
            transactionId: this.state.transactionId
          });
      });
  }

  onChallengeFactor = factorId => {
    const url = new URL(this.state.apiBaseUrl + "/signin/factors/challenge");
    const body = {
      factorId,
      stateToken: this.state.verificationStateToken,
      clientId: this.props.params.client_id,
      username: this.state.username
    };

    this.setState({
      verificationFactors: this.state.verificationFactors.map(f => {
        return {
          ...f,
          isActive: f.id === factorId
        };
      })
    });

    return fetch(url.href, {
      headers: {
        "Content-Type": "application/vnd.coxauto.v1+json",
        "X-CoxAuto-Correlation-Id": this.state.transactionId
      },
      method: "POST",
      body: JSON.stringify(body),
      mode: "cors"
    })
      .then(response => {
        if (!response.ok) {
          if (response.status === 401) {
            // Either the user is locked out or they waited too long
            // Bounce back to signin
            this.setState({
              errorMsg: "error.INVALID_USER_OR_CODE"
            });
            this.props.history.push(
              this.props.bookMarkSolution,
              "usernamePage"
            );
            return false;
          } else if (response.status === 403) {
            // OKTA rate limit, display error
            this.setState({
              errorMsg: "error.MFA_RATE_LIMIT"
            });
            return false;
          } else {
            throw response.bodyUsed
              ? response.json()
              : { errorCode: "generic" };
          }
        }
        this.clearErrors();
        this.props.history.push(
          this.props.bookMarkSolution,
          "accountVerificationForm"
        );
        return true;
      })
      .catch(err => {
        this.setState({
          errorMsg: "error.generic"
        });
        if (window.NREUM)
          window.NREUM.noticeError(err, {
            transactionId: this.state.transactionId
          });

        return false;
      });
  };

  returnToChallengeOptions = e => {
    this.props.history.push(
      this.props.bookMarkSolution,
      "accountVerificationOptionsForm"
    );
  };

  returnToFactorEnrolmentOptions = e => {
    this.props.history.push(
      this.props.bookMarkSolution,
      "factorEnrollmentOptionsForm"
    );
  };

  onEnrollSuccess = () => {
    this.props.history.push(
      this.props.bookMarkSolution,
      "factorEnrollSuccessForm"
    );
    const unblock = this.props.history.block();
    this.setState({ unblock });
  };

  onVerifyFactor = passCode => {
    const url = new URL(this.state.apiBaseUrl + "/signin/factors/verify");
    const activeFactor = this.state.verificationFactors.filter(
      f => f.isActive
    )[0];
    const body = {
      factorId: activeFactor.id,
      stateToken: this.state.verificationStateToken,
      clientId: this.props.params.client_id,
      username: this.state.username,
      passCode
    };

    return fetch(url.href, {
      headers: {
        "Content-Type": "application/vnd.coxauto.v1+json",
        "X-CoxAuto-Correlation-Id": this.state.transactionId
      },
      method: "POST",
      body: JSON.stringify(body),
      mode: "cors"
    })
      .then(response => {
        if (!response.ok) {
          if (response.status === 403) {
            // This means the code was invalid, let the form handle it
            return false;
          } else if (response.status === 401) {
            // Either the user is locked out or they waited too long to enter the code
            // Bounce back to signin
            this.setState({
              errorMsg: "error.INVALID_USER_OR_CODE"
            });
            this.props.history.push(
              this.props.bookMarkSolution,
              "usernamePage"
            );
            return false;
          } else {
            throw response.bodyUsed
              ? response.json()
              : { errorCode: "generic" };
          }
        }
        this.clearErrors();
        return this.continueSignin();
      })
      .catch(err => {
        this.setState({
          errorMsg: "error.generic"
        });
        if (window.NREUM)
          window.NREUM.noticeError(err, {
            transactionId: this.state.transactionId
          });

        return "error.generic";
      });
  };

  continueSignin = () => {
    if (this.state.unblock) {
      this.state.unblock();
    }
    const submitParams = JSON.parse(JSON.stringify(this.props.params));
    submitParams.authenticationProvider = this.state.authenticationProvider;
    submitParams.password = this.state.password;
    return this.handleSignin(submitParams);
  };

  onEnrollFactor = (factorType, factorValue) => {
    const url = new URL(this.state.apiBaseUrl + "/signin/factors/enroll");
    const body = {
      factorType,
      token: this.state.mfaEnrollIdentityToken,
      clientId: this.props.params.client_id,
      emailAddress: factorType === "EMAIL" ? factorValue : null,
      phoneNumber: factorType === "SMS" ? factorValue : null
    };

    this.setState({
      enrollFactor: { factorType, translationParam: factorValue }
    });

    return fetch(url.href, {
      headers: {
        "Content-Type": "application/vnd.coxauto.v1+json",
        "X-CoxAuto-Correlation-Id": this.state.transactionId
      },
      method: "POST",
      body: JSON.stringify(body),
      mode: "cors"
    })
      .then(response => {
        if (!response.ok) {
          switch (response.status) {
            case 403:
              this.setState({
                errorMsg: "error.MFA_RATE_LIMIT"
              });
              return false;
            case 409:
              throw new Error("fieldError.emailInUse");
            case 422:
              throw new Error("fieldError.phoneInvalid");
            default:
              throw new Error("error.generic");
          }
        }
        this.clearErrors();
        if (response.status === 200) {
          // the factor has already been verified, so continue
          this.onEnrollSuccess();
        } else {
          this.props.history.push(
            this.props.bookMarkSolution,
            "enrollVerificationForm"
          );
        }
        return true;
      })
      .catch(err => {
        if (err.message.startsWith("fieldError")) throw err;
        this.setState({
          errorMsg: "error.generic"
        });
        if (window.NREUM)
          window.NREUM.noticeError(err, {
            transactionId: this.state.transactionId
          });

        return false;
      });
  };

  onVerifyEnrollFactor = (factorType, verificationCode) => {
    const url = new URL(
      this.state.apiBaseUrl + "/signin/factors/enroll-verify"
    );
    const body = {
      token: this.state.mfaEnrollIdentityToken,
      clientId: this.props.params.client_id,
      factorType,
      verificationCode
    };

    return fetch(url.href, {
      headers: {
        "Content-Type": "application/vnd.coxauto.v1+json",
        "X-CoxAuto-Correlation-Id": this.state.transactionId
      },
      method: "POST",
      body: JSON.stringify(body),
      mode: "cors"
    })
      .then(response => {
        if (!response.ok) {
          switch (response.status) {
            case 403: // This means the code was invalid, let the form handle it
              return false;
            case 409: // This means they already verified a factor, so success
              this.onEnrollSuccess();
              return false;
            default:
              throw response.bodyUsed
                ? response.json()
                : { errorCode: "generic" };
          }
        }
        this.clearErrors();
        return true;
      })
      .catch(err => {
        this.setState({
          errorMsg: "error.generic"
        });
        if (window.NREUM)
          window.NREUM.noticeError(err, {
            transactionId: this.state.transactionId
          });

        return "error.generic";
      });
  };

  onAcceptEula() {
    const url = new URL(this.state.apiBaseUrl + "/eula"),
      body = {
        token: this.state.eulaIdentityToken,
        clientId: this.props.params.client_id
      };
    return fetch(url, {
      headers: {
        "Content-Type": "application/json",
        "X-CoxAuto-Correlation-Id": this.state.transactionId
      },
      method: "POST",
      body: JSON.stringify(body),
      mode: "cors"
    })
      .then(response =>
        response.json().then(responseJson => {
          if (response.ok && responseJson.eulaAcceptedChallenge) {
            const submitParams = JSON.parse(JSON.stringify(this.props.params));
            submitParams.challengeState = responseJson.eulaAcceptedChallenge;
            submitParams.authenticationProvider = this.state.authenticationProvider;
            submitParams.password = this.state.password;
            return this.handleSignin(submitParams);
          } else {
            throw new Error("Missing eulaAcceptedChallenge");
          }
        })
      )
      .catch(err => {
        this.setState({
          errorMsg: "error.generic"
        });
        if (window.NREUM)
          window.NREUM.noticeError(err, {
            transactionId: this.state.transactionId
          });

        return false;
      });
  }

  reportThirdPartyCookieStatus(identityParams) {
    this.props
      .isThirdPartyCookieAllowed(
        identityParams.username,
        identityParams.client_id
      )
      .then(bool => {
        this.setState({ thirdPartyCookiesAllowed: bool });
      })
      .catch(() => {}); // no-op;
  }

  handleSignin(submitParams) {
    submitParams.username = this.state.username;
    submitParams.solutionid = this.props.solutionId;
    submitParams.transactionId = this.state.transactionId;

    return fetch(new URL(this.state.apiBaseUrl + "/signin").href, {
      headers: {
        "Content-Type": "application/vnd.coxauto.v1+json",
        Accept: "application/vnd.coxauto.v1+json",
        "X-CoxAuto-Correlation-Id": this.state.transactionId
      },
      method: "POST",
      body: JSON.stringify(submitParams)
    })
      .then(async res => ({ status: res.status, body: await res.json() }))
      .then(response => {
        const analyticsUser = response.body.analyticsUser;
        if (analyticsUser && analyticsUser.platformId) {
          identifyUser({
            bridgePlatformId: analyticsUser.platformId,
            bridgeUsername: analyticsUser.username
          });
          productEvent("User Identified");
        }

        if (response.status === 200) {
          productEvent(`Authenticated And Authorized`, {
            solutionId: this.props.solutionId
          });
        }

        if (response.body.redirectUrl) {
          endSession();
          switch (response.body.redirectMode) {
            case "FORM_POST":
              this.doSubmitForm(
                response.body.formParams,
                response.body.redirectUrl
              );
              break;
            case "GET":
            default:
              window.location.assign(response.body.redirectUrl);
          }
          return true;
        } else {
          this.handleErrorState(
            response.body.errorCode,
            response.body.errorAttributes
          );
          return false;
        }
      })
      .catch(err => {
        const errorAttrs = {
          ...this.state.errorAttrs,
          canShowDtLegacyLink: true
        };

        this.setState({
          errorMsg: "error.generic",
          errorAttrs
        });
        if (window.NREUM)
          window.NREUM.noticeError(err, {
            transactionId: this.state.transactionId
          });

        return false;
      });
  }

  getDTBackdoorLink() {
    if (
      // Validate Feature flag is enabled
      this.props.featureFlags[
        "pdt.enable-disable-dt-backdoor-login-on-csi-error-message"
      ]
    ) {
      const dtBackdoorUrl =
        this.state.solution.partition === "NON_PRODUCTION"
          ? "https://login-qa.dealertrack.com/login/dtlogin"
          : "https://login.dealertrack.com/login/dtlogin";
      const backdoorPartition =
        this.state.solution.partition === "NON_PRODUCTION"
          ? "NON_PRODUCTION"
          : "PRODUCTION";

      return (
        <Trans
          i18nKey="dtBackdoor"
          components={{
            legacyLink: (
              // Text will be injected by translation so disable lint rule below
              // eslint-disable-next-line jsx-a11y/anchor-has-content
              <a
                href={dtBackdoorUrl}
                onClick={e =>
                  linkClicked(
                    "Legacy DT Fallback",
                    "User redirected to " +
                      backdoorPartition +
                      " dealertrack legacy login",
                    "Header Alert Box"
                  )
                }
              />
            )
          }}
        />
      );
    } else {
      return null;
    }
  }

  handleErrorState(errorCode, attributes, passwordErrorCodes) {
    switch (errorCode) {
      case "VERIFICATION_REQUIRED":
        this.clearErrors();
        this.props.history.push(
          this.props.bookMarkSolution,
          "accountVerificationOptionsForm"
        );
        this.setState({
          verificationFactors: attributes["factors"],
          verificationStateToken: attributes["stateToken"]
        });
        report(
          "Verification Options Shown",
          "User Logged In",
          "User needs to verify account",
          "Account Verification Form"
        );
        break;

      case "EULA_REQUIRED":
        this.clearErrors();
        this.props.history.push(this.props.bookMarkSolution, "eulaPage");
        this.setState({
          eulaIdentityToken: attributes["eulaIdentityToken"]
        });
        report(
          "EULA Shown",
          "User Logged In",
          "User needs to accept EULA",
          "Eula Form"
        );
        break;
      case "EXPIRED_PASSWORD":
        this.clearErrors();
        this.props.history.push(
          this.props.bookMarkSolution,
          "passwordResetPage"
        );
        this.setState({
          passwordResetState: attributes["changePasswordToken"]
        });
        this.setState({
          passwordPolicy: attributes["passwordPolicy"]
        });
        break;
      case "FAILED_PASSWORD_CHANGE_REQUIREMENTS":
        // Display the password error messages.
        this.setState({
          passwordChangeBackendErrors: mapToTranslatedMessages(
            passwordErrorCodes
          )
        });
        break;
      case "MFA_ENROLLMENT_REQUIRED":
        this.clearErrors();
        this.props.history.push(
          this.props.bookMarkSolution,
          "factorEnrollmentOptionsForm"
        );
        this.setState({
          mfaEnrollIdentityToken: attributes["stateToken"],
          mfaEnrollmentForRecovery: attributes["forRecovery"]
        });
        report(
          "Factor Enrollment Options Shown",
          "User Logged In",
          "User needs to enroll with at least one factor",
          "Factor Enrollment Form"
        );
        break;
      case "USER_NOT_LINKED":
        this.props.history.push(
          this.props.bookMarkSolution,
          "userUnlinkedErrorPage"
        );

        productEvent(`Authenticated But Not Authorized`, {
          solutionId: this.props.solutionId
        });

        // As this page is a terminating page, let's make sure to stop and clear the timeout timer and block the back button
        clearInterval(intervalHolder);
        this.props.history.block(); // returns a function that can unblock, can take in function to provide prompt
        this.setState({
          timeRemaining: 0 // Hides the countdown timer box
        });
        break;
      case "USER_MUST_USE_BRIDGE":
        report(
          "Username",
          "Username Submitted",
          "Valid Solution User Linked to Bridge, shown bridge username required screen",
          "userLinkedToBridgeForm"
        );

        this.setState(
          {
            errorAttrs: {
              ...this.state.errorAttrs,
              ...attributes,
              I18N_KEY: "userLinkedToBridgeForm",
              username: this.state.username,
              solutionDisplayName: this.props.solutionDisplayName,
              BUTTON_ACTION: () => {
                this.setState(
                  { username: attributes["bridgeUsername"] }, // Update the username
                  () => {
                    report(
                      "Login with Bridge ID Button",
                      "Button Clicked",
                      "Valid Solution User Linked to Bridge, shown bridge username required screen",
                      "userLinkedToBridgeForm"
                    );

                    this.props.history.push(
                      this.props.bookMarkSolution,
                      "usernamePage"
                    ); // go back to the signin page, preserving history so they can go back to this page
                    // this.submitUsername(attributes['bridgeUsername']) // Submit the username to get to proper password screen
                  }
                );
              }
            }
          },
          () =>
            this.props.history.push(
              this.props.bookMarkSolution,
              "genericMessagePage"
            )
        );
        break;
      case "INVALID_USER_OR_PASSWORD":
        report(
          "Password Input",
          "Invalid username/password entered",
          "Triggered alert",
          "Password Form"
        );
        this.setState({ errorMsg: "error.INVALID_USER_OR_PASSWORD" });
        break;
      case "DUPLICATE_SESSION":
        report(
          "Username",
          "Username Submitted",
          "Duplicate Session Detected, asking user to confirm logout",
          "duplicateSession"
        );
        this.setState(
          {
            errorAttrs: {
              ...this.state.errorAttrs,
              ...attributes,
              retryCallback: () =>
                this.state.solutionInfo.passwordRequired
                  ? this.submitPassword(this.state.password)
                  : this.federate(this.state.solutionInfo.federationProvider)
            }
          },
          () => {
            this.props.history.push(
              this.props.bookMarkSolution,
              "duplicateSessionDetected"
            );
          }
        );
        break;
      case "USER_STATE_ERROR":
      case "SYSTEM_ERROR":
        attributes["canShowDtLegacyLink"] = true;
        this.setState({
          errorMsg: "error." + errorCode,
          errorAttrs: {
            ...this.state.errorAttrs,
            ...attributes
          }
        });
        break;
      case "INTERNAL_SERVER_ERROR":
      case "BAD_GATEWAY":
      case "SERVICE_UNAVAILABLE":
      case "GATEWAY_TIMEOUT":
        attributes["canShowDtLegacyLink"] = true;
        this.setState({
          errorMsg: "error.SYSTEM_ERROR",
          errorAttrs: {
            ...this.state.errorAttrs,
            ...attributes
          }
        });
        break;
      case "THIRD_PARTY_ACCESS_DENIED":
        this.props.history.push(
          this.props.bookMarkSolution,
          "thirdPartyAccessDenied"
        );
        // As this page is a terminating page, let's make sure to stop and clear the timeout timer and block the back button
        clearInterval(intervalHolder);
        this.props.history.block(); // returns a function that can unblock, can take in function to provide prompt
        this.setState({
          timeRemaining: 0 // Hides the alert box
        });
        break;

      default:
        if (window.NREUM)
          window.NREUM.noticeError(
            "Unhandled Error Code in error handler: " + errorCode,
            {
              transactionId: this.state.transactionId
            }
          );
        attributes["canShowDtLegacyLink"] = true;
        this.setState({
          errorMsg: "error.SYSTEM_ERROR",
          errorAttrs: {
            ...this.state.errorAttrs,
            ...attributes
          }
        });
    }
  }

  doSubmitForm(params, url) {
    const form = document.createElement("form");
    form.setAttribute("method", "post");
    form.setAttribute("action", url);

    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        const hiddenField = document.createElement("input");
        hiddenField.setAttribute("type", "hidden");
        hiddenField.setAttribute("name", key);
        hiddenField.setAttribute("value", params[key]);

        form.appendChild(hiddenField);
      }
    }
    document.body.appendChild(form);
    form.submit();
  }

  navigateBackAPage() {
    this.props.history.goBack();
  }

  clearErrors() {
    this.setState({ errorMsg: undefined });
  }

  render() {
    return (
      <div>
        <Header />
        <BroadcastMessage
          config={this.props.featureFlags["ops.broadcast-message"] || {}}
        />
        <div className={"app-content"}>
          {this.state.timeRemaining < timeoutThresholds.info &&
            this.state.timeRemaining !== 0 && (
              <Alert
                htmlId="loginWarning"
                type={
                  this.state.timeRemaining > timeoutThresholds.warningTime
                    ? "info"
                    : "warning"
                }
                displayCloseButton={false}
              >
                <Trans
                  i18nKey="timeoutMessage.countdown"
                  values={{ remainingTime: this.state.timeRemaining }}
                />
                {this.state.solution !== undefined &&
                  this.state.solution.solutionLandingUrl && (
                    <a href={window.location}>
                      &nbsp;
                      <Trans i18nKey="timeoutMessage.reload" />.
                    </a>
                  )}
              </Alert>
            )}
          {this.state.errorMsg && (
            <Alert
              htmlId="warningAlert"
              type="warning"
              displayCloseButton
              onCloseClick={this.clearErrors}
            >
              {/* eslint-disable react/no-danger */}
              <div
                dangerouslySetInnerHTML={{
                  __html: getI18n().t(
                    this.state.errorMsg,
                    {
                      solutionDisplayName: "" + this.props.solutionDisplayName,
                      username: this.state.username.toUpperCase()
                    },
                    { interpolation: { escapeValue: true } } // Escape the inputs (default but being explicit)
                  )
                }}
              />
              {this.state.errorAttrs &&
                this.state.errorAttrs.canShowDtLegacyLink &&
                this.getDTBackdoorLink()}
              {/* eslint-enable react/no-danger */}
            </Alert>
          )}
          <SignInControl
            abortUrl={this.props.abortUrl}
            authProviderDisplayName={
              this.state.solutionInfo ? this.state.solutionInfo.displayName : ""
            }
            availableSolutions={this.state.availableSolutions}
            username={this.state.username}
            contactUsUrl={
              this.state.solution !== undefined &&
              this.state.solution.contactUsUrl !== undefined
                ? this.state.solution.contactUsUrl
                : "https://www.coxautoinc.com/contact-us/"
            }
            solutionLogoURI={
              this.state.solution !== undefined &&
              this.state.solution.solutionLogoUrl !== undefined
                ? this.state.solution.solutionLogoUrl
                : undefined
            }
            currentPage={this.props.location.state || "usernamePage"}
            currentFlow={this.props.currentFlow}
            errorAttrs={this.state.errorAttrs}
            onAcceptEula={this.onAcceptEula}
            onSubmitUsername={this.submitUsername}
            onForgotUsernameClick={this.forgotUsername}
            onForgotPasswordClick={this.forgotPassword}
            onSubmitPassword={this.submitPassword}
            onSubmitPasswordChange={this.changePassword}
            onClickUsernameFromPasswordPage={() =>
              this.props.history.push(
                this.props.bookMarkSolution,
                "usernamePage"
              )
            }
            onClickSolutionName={this.submitSolutionName}
            solutionDisplayName={this.props.solutionDisplayName}
            solutionList={this.state.solutionList}
            solutionId={this.props.solutionId}
            apiBaseUrl={this.props.apiBaseUrl}
            clientId={this.props.params.client_id}
            showForgotPassword={
              this.state.solution &&
              this.state.solution.showForgotPassword !== undefined
                ? this.state.solution.showForgotPassword
                : true
            }
            showForgotUsername={
              this.state.solution &&
              this.state.solution.showForgotUsername !== undefined
                ? this.state.solution.showForgotUsername
                : true
            }
            bridgeNative={
              this.state.solution ? this.state.solution.bridgeNative : false
            }
            solutionLandingUrl={
              this.state.solution
                ? this.state.solution.solutionLandingUrl
                : undefined
            }
            recoverBridgeUsername={this.recoverBridgeUsername}
            featureFlags={this.props.featureFlags}
            onClickRecoveryEmailNextButton={this.onClickRecoveryEmailNextButton}
            onClickRecoverUsernameStatusBack={
              this.onClickRecoverUsernameStatusBack
            }
            recoveryEmail={this.state.usernameRecoveryEmail}
            verificationFactors={this.state.verificationFactors}
            onChallengeFactor={this.onChallengeFactor}
            onVerifyFactor={this.onVerifyFactor}
            onClickPasswordRecoveryByEmailFromOptionsForm={
              this.onClickPasswordRecoveryByEmailFromOptionsForm
            }
            onClickPasswordRecoveryBySmsFromOptionsForm={
              this.onClickPasswordRecoveryBySmsFromOptionsForm
            }
            closePasswordRecovery={this.closePasswordRecovery}
            verifyPasscode={this.verifyPasscode}
            showRecoveryOptions={this.showRecoveryOptions}
            resendPasscode={this.resendPasscode}
            onAccountRecoveryPasswordChange={
              this.onAccountRecoveryPasswordChange
            }
            fetchIdentityProviders={this.fetchIdentityProviders}
            returnToChallengeOptions={this.returnToChallengeOptions}
            returnToFactorEnrolmentOptions={this.returnToFactorEnrolmentOptions}
            passwordPolicy={this.state.passwordPolicy}
            submitFactorEnrollmentOption={this.submitFactorEnrollmentOption}
            onEnrollFactor={this.onEnrollFactor}
            onVerifyEnrollFactor={this.onVerifyEnrollFactor}
            enrollFactor={this.state.enrollFactor}
            onEnrollSuccess={this.onEnrollSuccess}
            onContinueSignin={this.continueSignin}
            passwordChangeBackendErrors={this.state.passwordChangeBackendErrors}
            partition={
              this.props.partition ||
              (this.state.solution
                ? this.state.solution.partition
                : "PRODUCTION")
            }
            mfaEnrollmentForRecovery={this.state.mfaEnrollmentForRecovery}
          />
        </div>
        {this.props.currentFlow !== "solutionIdentity" && <Footer />}
      </div>
    );
  }
}

App.propTypes = {
  abortUrl: PropTypes.string,
  apiBaseUrl: PropTypes.string.isRequired,
  bookMarkSolution: PropTypes.string,
  currentFlow: PropTypes.string,
  featureFlags: PropTypes.object.isRequired,
  forceErrorState: PropTypes.string,
  history: PropTypes.object.isRequired,
  idp: PropTypes.object,
  isThirdPartyCookieAllowed: PropTypes.func,
  location: PropTypes.object.isRequired,
  /* eslint-disable camelcase */
  params: PropTypes.shape({
    client_id: PropTypes.string.isRequired,
    response_type: PropTypes.string.isRequired,
    scope: PropTypes.string.isRequired,
    redirect_uri: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    nonce: PropTypes.string
  }).isRequired,
  partition: PropTypes.string,
  solution: PropTypes.string,
  solutionDisplayName: PropTypes.string,
  solutionId: PropTypes.string,
  transactionId: PropTypes.string,
  username: PropTypes.string
};

export default withRouter(App);

// For unit tests
export const AppComponent = App;
