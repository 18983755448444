import React from "react";
import SubmitButton from "@cx/ui/SubmitButton";
import EmailInput from "@cx/ui/EmailInput";
import { Trans, Translation } from "react-i18next";
import PropTypes from "prop-types";
import { linkClicked } from "../../api/analytics";

export default class UsernameRecoveryEmailCaptureForm extends React.Component {
  state = {
    emailValue: "",
    validEmail: "false"
  };

  onClickRecoveryEmailNextButton = () => {
    if (this.state.validEmail) {
      this.props.onClickRecoveryEmailNextButton(this.state.emailValue);

      linkClicked(
        "Forgot Username Recovery button",
        "Recover Bridge Username via Email",
        "Username Recovery Status Form"
      );
    } else {
      document.getElementById("RecoveryEmailInput").focus();
    }
  };

  onEmailInputKeydown = e => {
    if (e.key === "Enter") {
      e.preventDefault();
      e.stopPropagation();
      this.onClickRecoveryEmailNextButton(e);
    }
  };

  onChange = (cxEvent, isValid, domEvent) => {
    this.setState({
      emailValue: cxEvent.target.value,
      validEmail: isValid
    });
  };

  render() {
    return (
      <Translation>
        {t => (
          <form onSubmit={this.onFormSubmit}>
            <div>
              <Trans i18nKey="usernameRecoveryEmailCaptureForm.text" />
              <br />
              <br />
            </div>
            <EmailInput
              autoFocus
              htmlId="RecoveryEmailInput"
              label={t("usernameRecoveryEmailCaptureForm.inputLabel")}
              name="RecoveryEmailInput"
              maxLength={100}
              onChange={this.onChange}
              onKeyDown={this.onEmailInputKeydown}
              value={this.state.emailValue}
              required={true}
            />
            <div className="button-group pull-right">
              <SubmitButton
                disabled={!this.state.emailValue || !this.state.validEmail}
                htmlId="RecoveryEmailInputNextButton"
                buttonStyle="primary"
                onClick={this.onClickRecoveryEmailNextButton}
                type="button"
              >
                {t("usernameRecoveryEmailCaptureForm.nextButton")}
              </SubmitButton>
            </div>
          </form>
        )}
      </Translation>
    );
  }
}

UsernameRecoveryEmailCaptureForm.propTypes = {
  onClickRecoveryEmailNextButton: PropTypes.func.isRequired
};
